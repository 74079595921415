<template>
  <!-- Page title & actions -->
  <div class="border-b border-gray-200 px-4 pt-5 pb-4 bg-white bg-opacity-75 backdrop-filter backdrop-blur z-10 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 lg:sticky lg:top-0">
    <div class="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-yellow-400 via-yellow-600 to-yellow-300"></div>
    <div class="flex-1 min-w-0">
      <h1 class="text-xl font-bold text-gray-900 sm:text-2xl sm:truncate">
        This page isn't available yet...
      </h1>
    </div>
    <div class="mt-4 flex sm:mt-0 sm:ml-4">
      <!-- todo - buttons -->
    </div>
  </div>
<!-- 
  <div class="px-4 my-6 sm:px-6 lg:px-8">
    
  </div> -->

  <section>
    <div class="p-2 bg-gray-100 shadow sm:p-3">
      <div class="flex items-center justify-between flex-wrap">
        <div class="flex-1 flex items-center w-full">
          <span class="flex p-2 rounded-lg bg-white">
            <LockClosedIcon class="h-6 w-6 text-gray-700" aria-hidden="true" />
          </span>
          <p class="ml-3 font-medium text-gray-700 truncate">
            The Roof will be unlocked when 75% of Space Sneks have been adopted
          </p>
        </div>
        <div class="mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
          <a href="https://sneks.diamondhandshotel.com" target="_blank" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700">
            Mint a Space Snek
          </a>
        </div>
      </div>
    </div>
  </section>

  <div class="px-4 my-6 sm:px-6 lg:px-8">
    <section>
      <div class="relative">
        <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div class="relative rounded-2xl overflow-hidden">
          <div class="absolute inset-0">
            <img class="h-full w-full object-cover" :src="coverOuter" />
            <div class="absolute inset-0 bg-gradient-to-t from-gray-300 to-gray-500 mix-blend-multiply" />
          </div>
          <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span class="block text-white">An exclusive bar for</span>
              <span class="block text-pink-200">the biggest fans</span>
            </h1>
            <p class="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
              We help creators and influencers launch their own line of NFTs. The Roof is a secretive roof-top bar for holders to trade, game, chat and access exclusive rewards.
            </p>
            <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                <a href="https://discord.gg/CSMQz3tXFj" target="_blank" class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-pink-600 bg-pink-50 hover:bg-pink-100 sm:w-auto">
                  <DiscordIcon class="-ml-1.5 mr-2 h-6 w-6" aria-hidden="true" />
                  Join the Discord
                </a>
                <a href="https://twitter.com/DiamondHH" target="_blank" class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-pink-600 bg-pink-50 hover:bg-pink-100 sm:w-auto">
                  <TwitterIcon class="-ml-1.5 mr-2 h-6 w-6" aria-hidden="true" />
                  Follow on Twitter
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div>
    <section>
      <div class="relative bg-white py-16 overflow-hidden">
        <div class="relative">
          <div class="lg:mx-auto lg:max-w-7xl lg:px-12 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span class="h-12 w-12 rounded-md flex items-center justify-center bg-pink-600">
                    <SparklesIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div class="mt-6">
                  <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                    Unlock downloads, live streams, merchandise, events & more
                  </h2>
                  <p class="mt-4 text-lg text-gray-500">
                    Sign in with a Diamond Hands Hotel NFT to unlock exclusive rewards tied to every drop. Each experience is bespoke, and only available to holders.
                  </p>
                  <div class="mt-6">
                    <a href="https://diamondhandshotel.com/drops" target="_blank" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700">
                      Get a room key
                    </a>
                  </div>
                </div>
              </div>
              <div class="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p class="text-base text-gray-500">
                      &ldquo;You missed out on Bitcoin. Don't miss out on this.&rdquo;
                    </p>
                  </div>
                  <footer class="mt-3">
                    <div class="flex items-center space-x-2">
                      <div class="flex-shrink-0">
                        <UserCircleIcon class="h-6 w-6" aria-hidden="true" />
                      </div>
                      <div class="text-base font-medium text-gray-700">
                        Satoshi Nakamoto, CEO of Bitcoin
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div class="mt-12 sm:mt-16 lg:mt-0">
              <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" :src="previewOne" />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-24">
          <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div class="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2 flex items-center">
              <div>
                <div>
                  <span class="h-12 w-12 rounded-md flex items-center justify-center bg-pink-600">
                    <LockClosedIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div class="mt-6">
                  <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                    No rug pulls, guaranteed
                  </h2>
                  <p class="mt-4 text-lg text-gray-500">
                    We only work with vetted influencers, creators and other public figures. Every drop is entirely custom and carefully structured with a multi-year roadmap and established tokenomics.
                  </p>
                  <div class="mt-6">
                    <a href="https://diamondhandshotel.com/lobby" target="_blank" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div class="px-4 -ml-20 sm:px-6 md:-ml-14 lg:px-0 lg:m-0">
                <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-4">
                  <div class="relative aspect-w-1 aspect-h-1 rounded-2xl shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden pointer-events-none">
                    <img class="absolute inset-0 h-full w-full object-cover" src="@/assets/img/demo/ksi.jpg" alt="" />
                  </div>
                  <div class="relative paspect-w-1 aspect-h-1 rounded-2xl shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden pointer-events-none">
                    <img class="absolute inset-0 h-full w-full object-cover" src="@/assets/img/demo/doja.jpg" alt="" />
                  </div>
                  <div class="relative aspect-w-1 aspect-h-1 rounded-2xl shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden pointer-events-none">
                    <img class="absolute inset-0 h-full w-full object-cover" src="@/assets/img/demo/poki.jpg" alt="" />
                  </div>
                  <div class="relative aspect-w-1 aspect-h-1 rounded-2xl shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden pointer-events-none">
                    <img class="absolute inset-0 h-full w-full object-cover" src="@/assets/img/demo/danny.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div class="mt-3 font-semibold tracking-wide uppercase text-xs text-gray-400">
                * Illustration Only, Not for Sale
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="bg-pink-600 overflow-hidden shadow">
        <div class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 class="text-3xl font-extrabold text-white sm:text-4xl">
            Get in first. Presale starts Nov 30th.
          </h2>
          <p class="mt-4 text-lg leading-6 text-pink-200">
            An ultra exclusive society of 1,024 highly evolved Sneks, that travelled the cosmos to open the Diamond Hands Hotel.
          </p>
          <div class="mt-8 space-x-3">
            <a href="https://sneks.diamondhandshotel.com" target="_blank" class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-pink-600 bg-white hover:bg-pink-50 sm:w-auto">
              <SnekIcon class="-ml-1.5 mr-2.5 h-6 w-6" aria-hidden="true" />
              Explore the Sneks
            </a>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="bg-white overflow-hidden shadow">
        <div class="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <svg class="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
            <defs>
              <pattern id="8b1b5f72-e944-4457-af67-0c6d15a99f38" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
          </svg>

          <div class="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
            <div class="lg:col-span-1">
              <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                A better way to support creators.
              </h2>
            </div>
            <dl class="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
              <div v-for="feature in features" :key="feature.name">
                <dt>
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-purple-500 text-white">
                    <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p class="mt-5 text-lg leading-6 font-medium text-gray-900">{{ feature.name }}</p>
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  {{ feature.description }}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { SparklesIcon, LockClosedIcon, EmojiHappyIcon, TicketIcon, TrendingUpIcon, GiftIcon, UserCircleIcon } from '@heroicons/vue/outline'
import { DiscordIcon, TwitterIcon, SnekIcon } from '@ui'
import coverOuter from '@/assets/img/cover-inner.png'
import previewOne from '@/assets/img/roof/preview-1.png'

const features = [
  {
    name: 'No subscriptions',
    description: 'We\'re bringing back real ownership. Buy once and get lifetime access, or sell it when you\'re finished. It\'s yours.',
    icon: EmojiHappyIcon,
  },
  {
    name: 'Freebies galore',
    description: 'Get access to our resident-only giveaways, free games, and win crypto and NFTs prizes worth up to $100k.',
    icon: GiftIcon,
  },
  {
    name: 'Community owned',
    description: '33% of secondary market proceeds go to The Pool. All holders can vote on what we use it for - airdrops, events, charity & more.',
    icon: TicketIcon,
  },
  {
    name: 'Collect & trade',
    description: 'HODL or try your luck on The Trading Floor with realtime rarity reports, upcoming drops and sneak peaks.',
    icon: TrendingUpIcon,
  },
]
</script>